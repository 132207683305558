import React,{useState} from 'react'
import './style.css'
import Headers from "../../components/header/header";
import GamesSlider from "../../components/gamesSlider/gamesSlider";
import NewsSlider from "../../components/newsSlider/newsSlider";
import Video from "../../assets/video/background-video.mp4";
import VideoPoster from "../../assets/img/background-video.jpg";
import playButtonImg from "../../assets/img/playVideo.svg";
import callofduty from "../../assets/img/callofduty.jpg"
import live_icon from "../../assets/img/live-icon.svg";
import competitor1 from "../../assets/img/image6.png";
import competitor2 from "../../assets/img/image7.png";
import Schedules from "../../components/schedules/schedules";
import PartnersSlider from "../../components/partnersSlider/partnersSlider";
import Footer from "../../components/footer/footer";


function Home () {
    let [videoPlayState,setVideoPlayState] = useState(false);


    let playVideoHandler = () => {
        setVideoPlayState(!videoPlayState);
        let posterVideo = document.getElementById("poster-video");

        if(videoPlayState == true){
            posterVideo.pause();
        }else{
            posterVideo.play();
        }
    }
    return(
        <main className="min-vh-100 bg-main">
            <Headers/>
            <GamesSlider/>
            <section className="video-poster">
                <video poster={VideoPoster} id={"poster-video"} controls>
                    <source src={Video} type="video/mp4"/>
                </video>
                {
                    videoPlayState === false ?
                        <div className="play-button" onClick={playVideoHandler}>
                            <img src={playButtonImg} alt="play"/>
                        </div> : null
                }
            </section>

            <div className="big-background-image">
                <section className="about padding-160">
                    <div className="container-fluid two-grid">
                        <div className="d-flex justify-content-center flex-column">
                            <h2 className="mg-b-90">About</h2>
                            <div className="desc">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </div>
                        </div>
                        <div className="img">
                            <img src={callofduty} alt="Call Of Duty"/>
                        </div>
                    </div>
                </section>
                <section className="live-translation-section padding-160">
                    <div className="container-fluid">
                        <div className="live-game mg-b-56">
                            <div className="live-icon">
                                <img src={live_icon} alt=""/> Live
                            </div>
                            <div className="competitors">
                                <div className="competitor">
                                     <img src={competitor1} alt=""/> BRO
                                </div>
                                <span className="vs">VS</span>
                                <div className="competitor">
                                     <img src={competitor2} alt=""/> NS
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="video-poster mg-b-60">
                        <video poster={VideoPoster} id={"poster-video"} controls>
                            <source src={Video} type="video/mp4"/>
                        </video>
                    </div>
                    <div className="main-btn m-auto">
                        Whatch Live
                    </div>
                </section>
                <section className="news-section padding-160">
                    <div className="container-fluid">
                        <h2 className="mg-b-90 ">LATEST NEWS</h2>
                        <div className="news-slider-parent">
                            <NewsSlider/>
                        </div>
                    </div>
                </section>
                <section className="week-schedule-section padding-160">
                    <div className="container-fluid">
                        <h2 className="mg-b-90 ">WEEK SCHEDULE</h2>
                        <Schedules/>
                    </div>
                </section>

            </div>
            <section className="partners-section padding-160">
                <div className="container-fluid">
                    <h2 className="mg-b-90 text-center">Our Partners</h2>
                    <PartnersSlider/>
                </div>
            </section>
            <Footer/>
        </main>

    )

}

export default Home
