import GamesSliderReact from 'react'
import {Link, NavLink} from 'react-router-dom'
import './style.css'
import Slider from "react-slick";
import live_icon from "../../assets/img/live-icon.svg"
import competitor1 from "../../assets/img/image6.png"
import competitor2 from "../../assets/img/image7.png"
import React,{useState} from 'react'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";

function Schedules() {
    const [value, onChange] = useState(new Date());
    console.log(value)
    return (
        <div>
            <div className="dateSelect">
                <div className="date">
                    <DatePicker
                        selected={value}
                        onChange={onChange}
                        format={"MM/dd"}
                        dateFormat="MM d"

                    />
                </div>

            </div>
            <div className="schedules">
                <div className="schedule">
                    <div className="time">
                        3:00 PM <span className="timezone">GMT+8</span>
                    </div>
                    <div className="competitors">
                        <div className="competitor">
                            <div>
                                BRO <img src={competitor1} alt=""/>
                            </div>
                            <div className="stats">
                                1W-9L
                            </div>
                        </div>
                        <span className="result">
                                        0 - 0
                                    </span>
                        <div className="competitor">
                            <div>
                                NS <img src={competitor2} alt=""/>
                            </div>
                            <div className="stats">
                                1W-9L
                            </div>
                        </div>
                    </div>
                    <div className="empty"></div>
                </div>
                <div className="schedule">
                    <div className="time">
                        3:00 PM <span className="timezone">GMT+8</span>
                    </div>
                    <div className="competitors">
                        <div className="competitor">
                            <div>
                                BRO <img src={competitor1} alt=""/>
                            </div>
                            <div className="stats">
                                1W-9L
                            </div>
                        </div>
                        <span className="result">
                                        0 - 0
                                    </span>
                        <div className="competitor">
                            <div>
                                NS <img src={competitor2} alt=""/>
                            </div>
                            <div className="stats">
                                1W-9L
                            </div>
                        </div>
                    </div>
                    <div className="empty"></div>
                </div>
                <div className="schedule">
                    <div className="time">
                        3:00 PM <span className="timezone">GMT+8</span>
                    </div>
                    <div className="competitors">
                        <div className="competitor">
                            <div>
                                BRO <img src={competitor1} alt=""/>
                            </div>
                            <div className="stats">
                                1W-9L
                            </div>
                        </div>
                        <span className="result">
                                        0 - 0
                                    </span>
                        <div className="competitor">
                            <div>
                                NS <img src={competitor2} alt=""/>
                            </div>
                            <div className="stats">
                                1W-9L
                            </div>
                        </div>
                    </div>
                    <div className="empty"></div>
                </div>
            </div>
        </div>

    )

}


export default Schedules;
