import React,{useState} from 'react'
import "./style.css"


function PagesTitle(props) {
    return (
        <div className="page-header">
            {props.title}
        </div>
    )

}


export default PagesTitle;
