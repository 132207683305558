import './App.css';
import 'react-bootstrap';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './app/css/style.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import News from "./app/containers/news/News";
import Home from "./app/containers/home/Home";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {

  return (
      <Router>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/news' component={News} />
        </Switch>
      </Router>
  );
}

export default App;
