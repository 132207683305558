import React from 'react'
import {Link, NavLink} from 'react-router-dom'
import './style.css'
import logo from '../../assets/img/logo.png';


function Headers() {
    return (
        <header className="header">
            <div className="container-fluid ">
                <div className="menu-main">
                    <Link to="/" className="logo">
                        <img src={logo} alt=""/>
                    </Link>
                    <Link to="/" className="menu-item">
                        Teams
                    </Link>
                    <Link to="/" className="menu-item">
                        Events
                    </Link>
                    <Link to="/" className="menu-item">
                        Videos
                    </Link>
                    <Link to="/" className="menu-item">
                        Stats
                    </Link>
                    <Link to="/" className="menu-item">
                        news
                    </Link>
                    <Link to="/" className="menu-item">
                        bracket
                    </Link>
                    <Link to="/" className="menu-item">
                        Merch
                    </Link>
                </div>
                <div className="log-in-btn main-btn">
                    LOG IN
                </div>
            </div>
        </header>
    )

}


export default Headers
