import React,{useState} from 'react'
import './style.css'
import Headers from "../../components/header/header";
import PagesTitle from "../../components/pagesTitle/pagesTitle";
import callofduty from "../../assets/img/callofduty.jpg";
import facebookShare from "../../assets/img/facebook-share.svg";
import twitterShare from "../../assets/img/twitter-share.svg";
import googleShare from "../../assets/img/google-share.svg";
import instagramShare from "../../assets/img/instagram-share.svg";
import filterLogo from "../../assets/img/filter-logo.svg";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel'
import Footer from "../../components/footer/footer";

function News () {
    let [toggleFilter,setToggleFilter] = useState(false);

    let openFilters = () => {
        setToggleFilter(!toggleFilter)
    }


    return(
        <main className="min-vh-100 bg-main news-page">
            <Headers/>
            <div className={"big-background-image"}>
                <PagesTitle title={"News"}/>
                <section className="min-vh-100 padding-b-140">
                    <div className="container-fluid " >
                        <div className="news-filter-section ">
                            <h3 className="title-results mg-b-20">Results(2021)</h3>
                            <div className="d-flex  ">
                                <div>
                                    <div className="news-parent mr-40 mg-b-60">
                                        <div className="news">
                                            <img src={callofduty} alt="img" className="main-img"/>
                                            <div>
                                                <div className="data-social d-flex align-items-center justify-content-between">
                                                    <div className="news-red mr-3">
                                                        Video
                                                    </div>
                                                    <div className="social d-flex align-items-center">
                                                        <span>SHARE</span>
                                                        <div className="links d-flex align-items-center justify-content-end">
                                                            <a href="#" className=""><img src={facebookShare} alt="social"/></a>
                                                            <a href="#" className=""><img src={twitterShare} alt="social"/></a>
                                                            <a href="#" className=""><img src={googleShare} alt="social"/></a>
                                                            <a href="#" ><img src={instagramShare} alt="social"/></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="desc">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="news">
                                            <img src={callofduty} alt="img" className="main-img"/>
                                            <div>
                                                <div className="data-social d-flex align-items-center justify-content-between">
                                                    <div className="news-red mr-3">
                                                        Video
                                                    </div>
                                                    <div className="social d-flex align-items-center">
                                                        <span>SHARE</span>
                                                        <div className="links d-flex align-items-center justify-content-end">
                                                            <a href="#" className=""><img src={facebookShare} alt="social"/></a>
                                                            <a href="#" className=""><img src={twitterShare} alt="social"/></a>
                                                            <a href="#" className=""><img src={googleShare} alt="social"/></a>
                                                            <a href="#" ><img src={instagramShare} alt="social"/></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="desc">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="news">
                                            <img src={callofduty} alt="img" className="main-img"/>
                                            <div>
                                                <div className="data-social d-flex align-items-center justify-content-between">
                                                    <div className="news-red mr-3">
                                                        Video
                                                    </div>
                                                    <div className="social d-flex align-items-center">
                                                        <span>SHARE</span>
                                                        <div className="links d-flex align-items-center justify-content-end">
                                                            <a href="#" className=""><img src={facebookShare} alt="social"/></a>
                                                            <a href="#" className=""><img src={twitterShare} alt="social"/></a>
                                                            <a href="#" className=""><img src={googleShare} alt="social"/></a>
                                                            <a href="#" ><img src={instagramShare} alt="social"/></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="desc">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="news">
                                            <img src={callofduty} alt="img" className="main-img"/>
                                            <div>
                                                <div className="data-social d-flex align-items-center justify-content-between">
                                                    <div className="news-red mr-3">
                                                        Video
                                                    </div>
                                                    <div className="social d-flex align-items-center">
                                                        <span>SHARE</span>
                                                        <div className="links d-flex align-items-center justify-content-end">
                                                            <a href="#" className=""><img src={facebookShare} alt="social"/></a>
                                                            <a href="#" className=""><img src={twitterShare} alt="social"/></a>
                                                            <a href="#" className=""><img src={googleShare} alt="social"/></a>
                                                            <a href="#" ><img src={instagramShare} alt="social"/></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="desc">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="news">
                                            <img src={callofduty} alt="img" className="main-img"/>
                                            <div>
                                                <div className="data-social d-flex align-items-center justify-content-between">
                                                    <div className="news-red mr-3">
                                                        Video
                                                    </div>
                                                    <div className="social d-flex align-items-center">
                                                        <span>SHARE</span>
                                                        <div className="links d-flex align-items-center justify-content-end">
                                                            <a href="#" className=""><img src={facebookShare} alt="social"/></a>
                                                            <a href="#" className=""><img src={twitterShare} alt="social"/></a>
                                                            <a href="#" className=""><img src={googleShare} alt="social"/></a>
                                                            <a href="#" ><img src={instagramShare} alt="social"/></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="desc">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="news">
                                            <img src={callofduty} alt="img" className="main-img"/>
                                            <div>
                                                <div className="data-social d-flex align-items-center justify-content-between">
                                                    <div className="news-red mr-3">
                                                        Video
                                                    </div>
                                                    <div className="social d-flex align-items-center">
                                                        <span>SHARE</span>
                                                        <div className="links d-flex align-items-center justify-content-end">
                                                            <a href="#" className=""><img src={facebookShare} alt="social"/></a>
                                                            <a href="#" className=""><img src={twitterShare} alt="social"/></a>
                                                            <a href="#" className=""><img src={googleShare} alt="social"/></a>
                                                            <a href="#" ><img src={instagramShare} alt="social"/></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="desc">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="load-more main-btn m-auto">
                                        Load More
                                    </div>
                                </div>
                                <div className={`filters ${toggleFilter ? "openFilter" : ""}`}>
                                    <div className="filter-title d-flex align-items-center" onClick={openFilters}>
                                            <img src={filterLogo} alt="filterLogo" className="mr-10"/>
                                            FILTER
                                    </div>
                                    {toggleFilter ?
                                        <>
                                            <div>
                                                <div className="filter-title mg-b-20 d-flex align-items-center">
                                                    Format
                                                </div>
                                                <FormControl component="fieldset">
                                                    <RadioGroup
                                                        aria-label="Format"
                                                        defaultValue="VIDEO"
                                                        name="radio-buttons-group"
                                                    >
                                                        <FormControlLabel value="VIDEO" control={<Radio />} label="VIDEO" />
                                                        <FormControlLabel value="Article" control={<Radio />} label="Article" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                            <div>
                                                <div className="filter-title mg-b-20 d-flex align-items-center">
                                                    Events
                                                </div>
                                                <FormControl component="fieldset">
                                                    <RadioGroup
                                                        aria-label="Events"
                                                        defaultValue="Spring"
                                                        name="radio-buttons-group"
                                                    >
                                                        <FormControlLabel value="Spring" control={<Radio />} label="Spring" />
                                                        <FormControlLabel value="summer" control={<Radio />} label="summer" />
                                                        <FormControlLabel value="international" control={<Radio />} label="international" />
                                                        <FormControlLabel value="ACADEMY" control={<Radio />} label="ACADEMY" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                            <div>
                                                <div className="filter-title mg-b-20 d-flex align-items-center">
                                                    CATEGORY
                                                </div>
                                                <FormControl component="fieldset">
                                                    <RadioGroup
                                                        aria-label="CATEGORY"
                                                        defaultValue="ANNOUNCEMENTS"
                                                        name="radio-buttons-group"
                                                    >
                                                        <FormControlLabel value="ANNOUNCEMENTS" control={
                                                            <Radio/>} label="ANNOUNCEMENTS" />
                                                        <FormControlLabel value="COMMENTARY" control={<Radio/>} label="COMMENTARY" />
                                                        <FormControlLabel value="HIGHLIGHTS" control={<Radio/>} label="HIGHLIGHTS" />
                                                        <FormControlLabel value="GUIDES" control={<Radio/>} label="GUIDES" />
                                                        <FormControlLabel value="PLAYER FEATURES" control={
                                                            <Radio/>} label="PLAYER FEATURES" />
                                                        <FormControlLabel value="MATCH FEATURES" control={
                                                            <Radio/>} label="MATCH FEATURES" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </>
                                    : null }

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer/>

        </main>

    )

}

export default News
