import GamesSliderReact from 'react'
import {Link, NavLink} from 'react-router-dom'
import './style.css'
import Slider from "react-slick";
import callofduty from "../../assets/img/callofduty.jpg"
import facebookShare from "../../assets/img/facebook-share.svg"
import twitterShare from "../../assets/img/twitter-share.svg"
import googleShare from "../../assets/img/google-share.svg"
import instagramShare from "../../assets/img/instagram-share.svg"
import nextIcon from "../../assets/img/next-icon.svg";
import prevIcon from "../../assets/img/prev-icon.svg";


function SampleNextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className={className}
            style={{...style, display: "block"}}
            onClick={onClick}
        >

            <img src={nextIcon}/>
        </div>
    );
}

function SamplePrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className={className}
            style={{...style, display: "block"}}
            onClick={onClick}
        >
            <img src={prevIcon}/>
        </div>
    );
}

function NewsSlider() {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className="news-slider">
            <Slider {...settings}>
                <div className="item news">
                    <img src={callofduty} alt="img" className="main-img"/>
                    <div>
                        <div className="data-social d-flex align-items-center justify-content-between">
                            <div className="data mr-3">
                                2021-07-01
                            </div>
                            <div className="social d-flex align-items-center">
                                <span>SHARE</span>
                                <div className="links d-flex align-items-center justify-content-end">
                                    <a href="#" className=""><img src={facebookShare} alt="social"/></a>
                                    <a href="#" className=""><img src={twitterShare} alt="social"/></a>
                                    <a href="#" className=""><img src={googleShare} alt="social"/></a>
                                    <a href="#" ><img src={instagramShare} alt="social"/></a>
                                </div>
                            </div>
                        </div>
                        <div className="desc">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </div>
                    </div>
                </div>
                <div className="item news">
                    <img src={callofduty} alt="img"  className="main-img"/>
                    <div>
                        <div className="data-social d-flex align-items-center justify-content-between">
                            <div className="data mr-3">
                                2021-07-01
                            </div>
                            <div className="social d-flex align-items-center">
                                <span>SHARE</span>
                                <div className="links d-flex align-items-center justify-content-end">
                                    <a href="#" className=""><img src={facebookShare} alt="social"/></a>
                                    <a href="#" className=""><img src={twitterShare} alt="social"/></a>
                                    <a href="#" className=""><img src={googleShare} alt="social"/></a>
                                    <a href="#" ><img src={instagramShare} alt="social"/></a>
                                </div>
                            </div>
                        </div>
                        <div className="desc">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </div>
                    </div>
                </div>
                <div className="item news">
                    <img src={callofduty} alt="img"  className="main-img"/>
                    <div>
                        <div className="data-social d-flex align-items-center justify-content-between">
                            <div className="data mr-3">
                                2021-07-01
                            </div>
                            <div className="social d-flex align-items-center">
                                <span>SHARE</span>
                                <div className="links d-flex align-items-center justify-content-end">
                                    <a href="#" className=""><img src={facebookShare} alt="social"/></a>
                                    <a href="#" className=""><img src={twitterShare} alt="social"/></a>
                                    <a href="#" className=""><img src={googleShare} alt="social"/></a>
                                    <a href="#" ><img src={instagramShare} alt="social"/></a>
                                </div>
                            </div>
                        </div>
                        <div className="desc">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </div>
                    </div>
                </div>
                <div className="item news">
                    <img src={callofduty} alt="img"  className="main-img"/>
                    <div>
                        <div className="data-social d-flex align-items-center justify-content-between">
                            <div className="data mr-3">
                                2021-07-01
                            </div>
                            <div className="social d-flex align-items-center">
                                <span>SHARE</span>
                                <div className="links d-flex align-items-center justify-content-end">
                                    <a href="#" className=""><img src={facebookShare} alt="social"/></a>
                                    <a href="#" className=""><img src={twitterShare} alt="social"/></a>
                                    <a href="#" className=""><img src={googleShare} alt="social"/></a>
                                    <a href="#" ><img src={instagramShare} alt="social"/></a>
                                </div>
                            </div>
                        </div>
                        <div className="desc">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </div>
                    </div>

                </div>
                <div className="item news">
                    <img src={callofduty} alt="img"  className="main-img"/>
                    <div>
                        <div className="data-social d-flex align-items-center justify-content-between">
                            <div className="data mr-3">
                                2021-07-01
                            </div>
                            <div className="social d-flex align-items-center">
                                <span>SHARE</span>
                                <div className="links d-flex align-items-center justify-content-end">
                                    <a href="#" className=""><img src={facebookShare} alt="social"/></a>
                                    <a href="#" className=""><img src={twitterShare} alt="social"/></a>
                                    <a href="#" className=""><img src={googleShare} alt="social"/></a>
                                    <a href="#" ><img src={instagramShare} alt="social"/></a>
                                </div>
                            </div>
                        </div>
                        <div className="desc">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </div>
                    </div>

                </div>


            </Slider>
        </div>
    )

}


export default NewsSlider;
