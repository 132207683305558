import React from 'react'
import {Link, NavLink} from 'react-router-dom'
import './style.css'
import logo from '../../assets/img/footer-logo.svg';


function Footer() {
    return (
        <footer className="footer">
            <div className="container-fluid d-flex flex-column">
                <div className="d-flex align-items-end justify-content-between border-bottom">
                    <div className="logo">
                        <img src={logo} alt=""/>
                    </div>
                    <div className="footer-links  d-flex flex-wrap">
                        <Link to={"#"} className="link">Lorem Ipsum</Link>
                        <Link to={"#"} className="link">Lorem Ipsum</Link>
                        <Link to={"#"} className="link">Lorem Ipsum</Link>
                        <Link to={"#"} className="link">Lorem Ipsum</Link>
                        <Link to={"#"} className="link">Lorem Ipsum</Link>
                    </div>
                </div>
                <div className="footer-copyright text-center">
                    Copyright Riot Games,All Rights Reserved.
                </div>
            </div>
        </footer>
    )

}


export default Footer
