import GamesSliderReact from 'react'
import {Link, NavLink} from 'react-router-dom'
import './style.css'
import Slider from "react-slick";
import live_icon from "../../assets/img/live-icon.svg"
import competitor1 from "../../assets/img/image6.png"
import competitor2 from "../../assets/img/image7.png"
import nextIcon from "../../assets/img/next-icon.svg"
import prevIcon from "../../assets/img/prev-icon.svg"

function SampleNextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className={className}
            style={{...style, display: "block"}}
            onClick={onClick}
        >

            <img src={nextIcon}/>
        </div>
    );
}

function SamplePrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className={className}
            style={{...style, display: "block"}}
            onClick={onClick}
        >
            <img src={prevIcon}/>
        </div>
    );
}

function GamesSlider() {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: true,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>,
        responsive: [
            {
                breakpoint: 1024,
                // variableWidth: false,

                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                // variableWidth: false,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                // variableWidth: false,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className="games-slider">
            <Slider {...settings}>
                <div className="item live-game">
                    <div className="live-icon">
                        <img src={live_icon} alt=""/> Live
                    </div>
                    <div className="competitors">
                        <div className="competitor">
                            BRO <img src={competitor1} alt=""/>
                        </div>
                        <span className="vs">VS</span>
                        <div className="competitor">
                            NS <img src={competitor2} alt=""/>
                        </div>
                    </div>
                    <div className="info">LCK</div>
                </div>
                <div className="item live-game">
                    <div className="live-icon">
                        <img src={live_icon} alt=""/> Live
                    </div>
                    <div className="competitors">
                        <div className="competitor">
                            BRO <img src={competitor1} alt=""/>
                        </div>
                        <span className="vs">VS</span>
                        <div className="competitor">
                            NS <img src={competitor2} alt=""/>
                        </div>
                    </div>
                    <div className="info">LCK</div>
                </div>
                <div className="item live-game">
                    <div className="live-icon">
                        <img src={live_icon} alt=""/> Live
                    </div>
                    <div className="competitors">
                        <div className="competitor">
                            BRO <img src={competitor1} alt=""/>
                        </div>
                        <span className="vs">VS</span>
                        <div className="competitor">
                            NS <img src={competitor2} alt=""/>
                        </div>
                    </div>
                    <div className="info">LCK</div>
                </div>
                <div className="item future-games">
                    <div className="date">
                        TODAY
                    </div>
                    <div className="games">
                        <div className="game">
                            <div className="competitors">
                                <div className="competitor">
                                     <img src={competitor1} alt=""/> BRO
                                </div>
                                <div className="competitor">
                                     <img src={competitor2} alt=""/> NS
                                </div>
                            </div>
                            <div className="time">8PM</div>
                        </div>
                        <div className="game">
                            <div className="competitors">
                                <div className="competitor">
                                    <img src={competitor1} alt=""/> BRO
                                </div>
                                <div className="competitor">
                                    <img src={competitor2} alt=""/> NS
                                </div>
                            </div>
                            <div className="time">8PM</div>
                        </div>
                        <div className="game">
                            <div className="competitors">
                                <div className="competitor">
                                    <img src={competitor1} alt=""/> BRO
                                </div>
                                <div className="competitor">
                                    <img src={competitor2} alt=""/> NS
                                </div>
                            </div>
                            <div className="time">8PM</div>
                        </div>
                        <div className="game">
                            <div className="competitors">
                                <div className="competitor">
                                    <img src={competitor1} alt=""/> BRO
                                </div>
                                <div className="competitor">
                                    <img src={competitor2} alt=""/> NS
                                </div>
                            </div>
                            <div className="time">8PM</div>
                        </div>
                        <div className="game">
                            <div className="competitors">
                                <div className="competitor">
                                    <img src={competitor1} alt=""/> BRO
                                </div>
                                <div className="competitor">
                                    <img src={competitor2} alt=""/> NS
                                </div>
                            </div>
                            <div className="time">8PM</div>
                        </div>

                    </div>
                </div>
                <div className="item future-games">
                    <div className="date">
                        TOMORROW
                    </div>
                    <div className="games">
                        <div className="game">
                            <div className="competitors">
                                <div className="competitor">
                                    <img src={competitor1} alt=""/> BRO
                                </div>
                                <div className="competitor">
                                    <img src={competitor2} alt=""/> NS
                                </div>
                            </div>
                            <div className="time">8PM</div>
                        </div>
                        <div className="game">
                            <div className="competitors">
                                <div className="competitor">
                                    <img src={competitor1} alt=""/> BRO
                                </div>
                                <div className="competitor">
                                    <img src={competitor2} alt=""/> NS
                                </div>
                            </div>
                            <div className="time">8PM</div>
                        </div>
                        <div className="game">
                            <div className="competitors">
                                <div className="competitor">
                                    <img src={competitor1} alt=""/> BRO
                                </div>
                                <div className="competitor">
                                    <img src={competitor2} alt=""/> NS
                                </div>
                            </div>
                            <div className="time">8PM</div>
                        </div>
                        <div className="game">
                            <div className="competitors">
                                <div className="competitor">
                                    <img src={competitor1} alt=""/> BRO
                                </div>
                                <div className="competitor">
                                    <img src={competitor2} alt=""/> NS
                                </div>
                            </div>
                            <div className="time">8PM</div>
                        </div>
                        <div className="game">
                            <div className="competitors">
                                <div className="competitor">
                                    <img src={competitor1} alt=""/> BRO
                                </div>
                                <div className="competitor">
                                    <img src={competitor2} alt=""/> NS
                                </div>
                            </div>
                            <div className="time">8PM</div>
                        </div>

                    </div>
                </div>

            </Slider>
        </div>
    )

}


export default GamesSlider;
